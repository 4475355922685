<template>
  <div>
    <v-dialog v-model="visible" persistent max-width="850">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Group NIP
        </v-card-text>
        <v-divider class="mb-4" />
        <v-text-field
          v-model="filter.search"
          placeholder="Cari"
          outlined
          dense
          hide-details
          prepend-inner-icon="search"
        ></v-text-field>
        <v-data-table
          class="mt-3"
          :headers="headers"
          :items="employees"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems
          }"
          loading-text="Loading... Please wait"
          item-key="no"
        >
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td class="pt-2">
                {{ item.groupname }}
              </td>
              <td class="pt-2">
                {{ item.unit_utama_name }}
              </td>
              <td class="pt-2">
                {{ item.unit_kerja_2_name }}
              </td>
              <td class="pt-2">
                {{ item.total_employee }}
              </td>
              <td>
                <v-btn
                  small
                  icon
                  color="#primary"
                  class="ml-2"
                  @click="() => handleView(item)"
                >
                  <v-icon>list</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  color="#FBB005"
                  class="ml-2"
                  :disabled="disabledItem(item)"
                  @click="() => handlePick(item)"
                >
                  <v-icon>check_circle_outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <ListGeneralDialog ref="employeeList" :maxWidth="500" />
  </div>
</template>
<script>
import GroupNIPService from "@/services/resources/group.nip.service";
const ListGeneralDialog = () => import("@/components/Dialog/ListGeneral");

export default {
  components: {
    ListGeneralDialog
  },
  props: {
    lists: { type: Array, required: true },
    value: { type: String, required: true },
    singlePick: { type: Boolean, required: false }
  },
  data() {
    return {
      visible: false,
      loading: false,
      employees: [],
      filter: {
        search: ""
      },
      options: {
        sortBy: ["groupname"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      headers: [
        {
          text: "Nama",
          value: "groupname",
          sortable: false,
          align: "center"
        },
        {
          text: "Unit Utama",
          value: "unit_utama_name",
          sortable: false,
          align: "center"
        },
        {
          text: "Unit Kerja",
          value: "unit_kerja_2_name",
          sortable: false,
          align: "center"
        },
        {
          text: "Jumlah Pegawai",
          value: "total_employee",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  computed: {
    paginationProperty() {
      return [this.filter.search].join();
    }
  },
  watch: {
    paginationProperty() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListEmployee);
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    }
  },
  methods: {
    disabledItem(item) {
      let index = this.lists.findIndex(d => d[this.value] == item[this.value]);
      if (index < 0) return false;
      return true;
    },
    change(val) {
      this.visible = val;
    },
    handlePick(item) {
      this.$emit("handle-pick", item);
      if (this.singlePick) {
        this.visible = false;
      }
    },
    handleView(item) {
      this.$refs.employeeList.view({
        title: "Pegawai",
        lists: item.pegawaiList,
        headers: [
          {
            text: "NIP",
            value: "NIP",
            sortable: false,
            align: "center"
          },
          {
            text: "Nama",
            value: "nama",
            sortable: false,
            align: "center"
          }
        ]
      });
    },
    // Service
    async getListEmployee() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await GroupNIPService.getListActive({
          filter: {
            search: this.filter.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              let employees = data;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                data.total_employee = data.pegawaiList
                  ? data.pegawaiList.length
                  : 0;
                return data;
              });

              this.employees = employees;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListEmployee();
  }
};
</script>
