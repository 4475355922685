import BaseInstance from "../base.instance";
import {
  PRESENSI_GROUP_NIP_LIST,
  PRESENSI_GROUP_NIP_DETAIL,
  PRESENSI_GROUP_NIP_SAVE,
  PRESENSI_GROUP_NIP_DELETE,
  PRESENSI_GROUP_NIP_LIST_ACTIVE
} from "../constants";

const GroupNIPService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_GROUP_NIP_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(PRESENSI_GROUP_NIP_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(PRESENSI_GROUP_NIP_SAVE, data);
  },
  delete(data) {
    return BaseInstance.post(PRESENSI_GROUP_NIP_DELETE, data);
  },
  getListActive(data) {
    return BaseInstance.post(PRESENSI_GROUP_NIP_LIST_ACTIVE, data);
  }
};

export default GroupNIPService;
